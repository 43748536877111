/* eslint-disable react/display-name */
import React, {forwardRef} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';

const CircleWithCheck = forwardRef((props, ref) => {
  const {checked} = props;

  return (
    <div ref={ref} style=
      {{width: 24,
        height: 24,
        position: 'relative',
        backgroundColor: 'transparent'}}>
      <svg width="100%" height="100%" viewBox="0 0 30 30">
        <circle
          cx="15"
          cy="15"
          r="13"
          fill={checked ? '#515abb' : 'white'}
          stroke="#515abb" strokeWidth="3" />
      </svg>
      {checked && <CheckIcon fontSize="small" style={{
        color: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
      }} />}
    </div>
  );
});

CircleWithCheck.propTypes = {
  /**
   * indicate checked or not checked
   */
  checked: PropTypes.bool,
};

CircleWithCheck.defaultProps = {
  checked: false,
};

export default CircleWithCheck;
