import React from 'react';
import {ButtonGroup, IconButton} from '@mui/material';
import {FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
  FaEnvelope} from 'react-icons/fa';
import {FaMedium} from 'react-icons/fa6';
import PropTypes from 'prop-types';
import {CiGlobe} from 'react-icons/ci';

/**
 * SocialMediaButtonGroup component
 * @return {JSX.Element} - Rendered component
 */
function SocialMediaButtonGroup({links}) {
  const ICONS = {
    facebook: {
      component: FaFacebookF,
      color: '#1877F2',
    },
    twitter: {
      component: FaTwitter,
      color: '#1DA1F2',
    },
    instagram: {
      component: FaInstagram,
      color: '#C32AA3',
    },
    linkedin: {
      component: FaLinkedinIn,
      color: '#0077B5',
    },
    medium: {
      component: FaMedium,
      color: 'black',
    },
    github: {
      component: FaGithub,
      color: 'black',
    },
    email: {
      component: FaEnvelope,
      color: 'primary',
    },
    website: {
      component: CiGlobe,
      color: 'primary',
    },
  };
  return (
    <ButtonGroup variant="text" aria-label="text primary button group">
      {Object.entries(links).map(([key, value]) => {
        if (value && ICONS[key]) {
          const {component: Icon, color} = ICONS[key];
          return (
            <IconButton
              key={key}
              style={{color: color === 'primary' ? undefined : color}}
              onClick={() =>
                key === 'email' ?
                window.location.href = value :
                window.open(value, '_blank')}
            >
              <Icon />
            </IconButton>
          );
        }
        return null;
      })}
    </ButtonGroup>
  );
}

SocialMediaButtonGroup.propTypes = {
  links: PropTypes.object,
};

SocialMediaButtonGroup.defaultProps = {
  links: {
    linkedin: 'www.example.com',
    facebook: 'www.example.com',
    twitter: 'www.example.com',
    email: 'mary@example.com',
  },
};

export default SocialMediaButtonGroup;
