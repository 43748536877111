import axios from 'axios';
import {baseURL} from './setEnvironment';

export const fetchRoomData = async (token, isDemo) => {
  try {
    console.log('fetching room data');
    console.log('base url:' + baseURL());
    console.log('isDemo: ' + isDemo);
    console.log('token value: ' + token);

    if (!isDemo) {
      const requestConfig = {
        url: baseURL()+'/get-room',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'authorization': `Bearer ${token}`,
        },
      };
      const response = await axios(requestConfig);
      return response.data;
    } else {
      const requestConfig = {
        url: baseURL()+'/get-demo-room',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'authorization': `Bearer ${token}`,
        },
      };
      const response = await axios(requestConfig);
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

