/* eslint-disable linebreak-style */
import React from 'react';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import TourIcon from '@mui/icons-material/Tour';

const AlertMessage = React.memo(({message}) => {
  return (
    <Box sx={{display: 'flex', flexWrap: 'wrap',
      alignItems: 'center', background: '#9FA8DA'}}>
      <TourIcon xs={{color: '#303F9F'}}
        fontSize='large'/>
      <Typography variant='body3'
        sx={{color: 'black', mx: 1}}>{message}</Typography>
    </Box>
  );
});

AlertMessage.displayName = 'AlertMessage';


AlertMessage.propTypes = {
  message: PropTypes.string,
};

AlertMessage.defaultProps = {
  message: 'This is a test message',
};

export default AlertMessage;
