import React from 'react';
import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import Annie from '../../assets/images/girl-persona.png';

const DemoImg = ({img, moodColor}) => {
  console.log(moodColor);
  return (
    <Box
      mt={2}
      sx={{
        width: '170px',
        height: '170px',
        display: 'inline-block',
        borderRadius: '50%', // This ensures the Box is circular
        background: moodColor,
        p: '10px', // This padding effectively acts as the border width
        boxShadow: `0px 2px 4px rgba(0, 0, 0.5, 0.5)`,
      }}
    >
      <Box
        component="img"
        src={img}
        alt="a circular avatar of a young animated boy with a cheerful
         expression, with a maze-like pattern in the background"
        sx={{
          width: 'auto',
          height: '164px',
          borderRadius: '50%',
          border: 'solid 3px',
          display: 'block',
          objectFit: 'cover',
        }}
      />
    </Box>);
};


DemoImg.propTypes = {
  img: PropTypes.object,
  moodColor: PropTypes.string,
};

DemoImg.defaultProps = {
  img: Annie,
  moodColor: '#000',
};


export default DemoImg;
